<template>
  <div class="our-studio-container" v-if="studio">
    <div class="title">{{ studio.title }}</div>
    <div class="description mt-6">
      {{ studio.description }}
    </div>
    <div class="my-8 relative">
      <swiper
        :slides-per-view="4"
        :space-between="20"
        :navigation="navigation"
        :breakpoints="breakpoints"
        :loop="true"
      >
        <swiper-slide v-for="(img, index) in images" :key="index">
          <div class="studio-img " :style="`background: url(${img})`">
            <div class="view-fullscreen " @click="showImg(index)">
              <div class="border-2 ico-border border-white h-14 w-14 rounded-full flex justify-center items-center opacity-0">
              <img src="/static/images/icons/fullscreen.svg" class="w-8 h-8 " /></div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="slide-right-btn" id="nextImg">
        <i class="fas fa-arrow-right text-bluePrimary"></i>
      </div>
      <div class="slide-left-btn" id="prevImg">
        <i class="fas fa-arrow-right text-bluePrimary"></i>
      </div>
      <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/components/navigation/navigation.min.css";
SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  inject: ["content"],
  data() {
    return {
      imgs: ["/static/images/collection-img.png"],
      visible: false,
      index: 0,
      navigation: {
        nextEl: "#nextImg",
        prevEl: "#prevImg",
      },
      breakpoints: {
        // when window width is >= 320px
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        567: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 640px
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1800: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      },
    };
  },
  computed: {
    studio() {
      return this.$store.getters["shops/studio"];
    },
    images() {
      let images = [];

      if (this.studio?.image) images.push(this.studio?.image);

      if (this.studio?.images) {
        for (let i = 0; i < this.studio?.images?.length; i++) {
          images.push(this.studio?.images[i].url);
        }
      }

      return images;
    },
  },
  methods: {
    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
  },
};
</script>
